<template>
  <div class="user-login">
    <img class="user-bg" src="@/assets/images/登陆_矩形.png" alt="" />
    <div class="concat-account">
      <img class="weixin-log" src="@/assets/images/企业微信@2x.png" alt="" />
      <div class="top-title">企微信息</div>
      <div class="concat-account-content">
        <div class="person-card">
          <img :src="user ? user.avatar : ''" alt="" :onerror="defaultImg" />
          <div class="person-detail">
            <div class="name">{{ user.name }}</div>
            <div class="position">{{ user.position }}</div>
          </div>
        </div>
        <div class="divider"></div>
        <div class="account">
          <div class="title">关联账号</div>
          <van-form @submit="onSubmit" validate-trigger="onChange">
            <van-field clearable maxlength="11" name="account" class="person-login" size="large" v-model.trim="person.account" placeholder="请输入账号" :rules="[{ required: true }]" />
            <van-field
              clearable
              type="password"
              name="password"
              class="person-login person-password"
              size="large"
              v-model.trim="person.password"
              placeholder="请输入密码"
              :rules="[{ required: true }]"
            />
            <!-- <van-field disabled /> -->
            <van-button native-type="submit" class="btn" type="info">确认进行关联 </van-button>
          </van-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import login from '@/api/login';
import sidebar from '@/api/sidebar';

let Base64 = require('js-base64').Base64;

export default {
  name: 'concatAccount',
  data() {
    return {
      changeBind: '', //是否是换绑操作
      user: {},
      person: {
        account: '',
        password: '',
      },
    };
  },
  computed: {
    defaultImg() {
      return 'this.src="' + require('@/assets/images/default_head_icon.png') + '"';
    },
  },
  methods: {
    async onSubmit(values) {
      let user = JSON.parse(localStorage.getItem('platform_user'));
      let param = {
        authType: 1,
        wecomAuthQo: {
          userId: this.user.userId,
          qrCode: this.user.qrCode,
          corpId: this.user.corpId,
          opId: localStorage.getItem('platform_opId'),
          avatar: user.avatar,
          mobile: user.mobile,
          name: user.name,
          thumbAvatar: user.thumbAvatar,
        },
        phone: values.account,
        pwd: Base64.encode(values.password),
      };
      let { code, data } = await login.homeLogin(param);
      if (code === '0') {
        localStorage.removeItem('platform_estateId');
        localStorage.removeItem('platform_sidebarEstateId');
        let { code } = await login.setToken(data.token);
        if (code === '0') {
          await this.getEnter(this.judgeEnter);
        }
      }
    },
    //根据进入方式(侧边栏 or 应用内)走相应流程
    async judgeEnter() {
      //       contact_profile	从联系人详情进入
      //       single_chat_tools	从单聊会话的工具栏进入
      //       group_chat_tools	从群聊会话的工具栏进入
      //       chat_attachment	从会话的聊天附件栏进入（企业微信3.1.6及以上版本才返回）
      //       normal	除以上场景之外进入，例如工作台，聊天会话等
      //判断是否是侧边栏进入，不是则走正常流程
      if (localStorage.getItem('platform_sidebar') !== 'normal') {
        this.$wx.invoke('getCurExternalContact', {}, async (res) => {
          await this.jumpSidebar(res);
        });
      } else {
        let opId = localStorage.getItem('platform_opId');
        let user = JSON.parse(localStorage.getItem('platform_user'));
        let { code, data } = await login.authority({ opId });
        if (code === '0') {
          if (!localStorage.getItem('platform_estateId') || !data.some((item) => item.estateId === localStorage.getItem('platform_estateId'))) {
            localStorage.setItem('platform_estateId', data[0].estateId);
            localStorage.setItem('platform_estateName', data[0].estateName);
            localStorage.setItem(
              'platform_role',
              JSON.stringify({
                saleRoleName: data[0].roleAuthorities[0].saleRoleName,
                saleUserId: data[0].roleAuthorities[0].saleUserId,
                name: user.name,
              })
            );
          }
          //获取应用权限
          let res = await login.appAuthority({ estateId: localStorage.getItem('platform_estateId') });
          if (res.code === '0') {
            let appPermit = res.data.map((item) => item.permit);
            localStorage.setItem('platform_appPermit', JSON.stringify(appPermit));
            localStorage.setItem('platform_appNews', JSON.stringify(res.data));
          }
          if (this.$route.query.redirect && this.$route.query.redirect !== 'undefined') {
            window.location.href = this.$route.query.redirect;
          } else if (localStorage.getItem('redirect')) {
            let redirect = localStorage.getItem('redirect');
            localStorage.removeItem('redirect');
            window.location.href = decodeURIComponent(redirect);
          } else {
            let role = JSON.parse(localStorage.getItem('platform_role'));
            if (role && role.saleRoleName === '销售经理') {
              this.$router.push('/manager');
            } else {
              this.$router.push('/consultant');
            }
          }
        }
      }
    },
    //侧边栏流程(crm&潜客)
    async jumpSidebar(res) {
      if (res.err_msg == 'getCurExternalContact:ok') {
        //缓存侧边栏进入外部联系人userId
        console.log('赋值1', res.userId);
        localStorage.setItem('platform_qwExternalUserid', res.userId);
        let { code, data } = await sidebar.getSidebarEstates({
          opId: localStorage.getItem('platform_opId'),
        });
        if (code === '0') {
          if (!localStorage.getItem('platform_sidebarEstateId') || !data.some((item) => item.estateId === localStorage.getItem('platform_sidebarEstateId'))) {
            localStorage.setItem('platform_sidebarEstateId', data[0].estateId);
          }
          if (this.$route.query.state === 'CME') {
            let jumpHost = `${window.location.protocol}//` + location.host;
            window.location.href = jumpHost + '/cme/#/sidebar';
          } else {
            await this.judgeAppPermit();
          }
        }
      } else {
        alert('获取userid失败:' + res.err_msg);
        //错误处理
      }
    },
    //侧边栏流程 跳转应用逻辑
    async judgeAppPermit() {
      //获取应用权限
      let res = await login.appAuthority({ estateId: localStorage.getItem('platform_sidebarEstateId') });
      if (res.code === '0') {
        let appPermit = res.data.map((item) => item.permit);
        if (appPermit.some((item) => item === 'potential')) {
          let params = {
            qwExternalUserid: localStorage.getItem('platform_qwExternalUserid'),
            estateId: localStorage.getItem('platform_sidebarEstateId'),
            opId: localStorage.getItem('platform_opId'),
          };
          //先判断潜客归属,再看是否跳转scrm;
          let { code, data } = await sidebar.sidebarBelongClue(params);
          if (code === '0') {
            if (data.hasConverted === 1) {
              await this.jumpScrm(appPermit, data);
            } else if (data.hasConverted === 0) {
              await this.jumpPotential(data);
            }
          }
        } else {
          let jumpHost = `${window.location.protocol}//` + location.host;
          window.location.href = jumpHost + `/#/abnormal?code=${-1000}&msg=当前项目未启用潜客宝`;
        }
      }
    },

    //跳转scrm详情
    async jumpScrm(appPermit, data) {
      if (appPermit.some((item) => item === 'scrm')) {
        let val = await sidebar.sidebarBelong({
          estateId: localStorage.getItem('platform_sidebarEstateId'),
          clueId: data.clueId,
          opId: localStorage.getItem('platform_opId'),
        });
        //判断用户归属
        if (val.code === '0') {
          let jumpHost = `${window.location.protocol}//` + location.host;
          switch (val.data.judgeFlag) {
            case 1:
              window.location.href = jumpHost + '/crm/#/sidebar/turnClient';
              break;
            case 2:
              window.location.href = jumpHost + '/crm/#/sidebar/noClient';
              break;
            case 3:
              window.location.href = jumpHost + `/crm/#/sidebar/detail?oppId=${val.data.oppId}`;
              break;
            case 4:
              window.location.href = jumpHost + '/crm/#/sidebar/turnClient';
              break;
            default:
              break;
          }
        }
      } else {
        let jumpHost = `${window.location.protocol}//` + location.host;
        window.location.href = jumpHost + `/#/abnormal?code=${-1000}&msg=该好友已转化为客户，当前项目未启用CRM`;
      }
    },
    //跳转潜客详情
    async jumpPotential(data) {
      let jumpHost = `${window.location.protocol}//` + location.host;
      switch (data.clueBelongFlag) {
        case 0:
          window.location.href = jumpHost + '/potential/#/sidebar/noClient';
          break;
        case 1:
          window.location.href = jumpHost + `/potential/#/sidebar/detail?clueId=${data.clueId}`;
          break;
        default:
          break;
      }
    },
    //调取微信sdk判断入口方式
    async getEnter(cb) {
      let suiteId = localStorage.getItem('platform_agentId');
      let corpId = localStorage.getItem('platform_corpId');
      let opId = localStorage.getItem('platform_opId');
      const targetUrl = location.href.split('#')[0];
      let params = { url: targetUrl, suiteId, corpId, opId };
      let res = await login.getEnter(params);
      if (res.code === '0') {
        this.$wx.config({
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          appId: corpId, // 必填，企业微信的corpID
          timestamp: res.data.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.data.noncestr, // 必填，生成签名的随机串
          signature: res.data.configSignature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
          jsApiList: ['chooseImage'], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
        });
        this.$wx.ready(()=>{
          this.$wx.agentConfig({
            corpid: corpId, // 必填，企业微信的corpid，必须与当前登录的企业一致
            agentid: suiteId, // 必填，企业微信的应用id （e.g. 1000247）
            timestamp: res.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.noncestr, // 必填，生成签名的随机串
            signature: res.data.agentConfigSignature, // 必填，签名，见附录-JS-SDK使用权限签名算法
            jsApiList: ['getCurExternalContact', 'getContext'], //必填，传入需要使用的接口名称  !!!后续要使用getCurExternalContact故加入
            success: (res) => {
              console.log('agentConfig 注册成功', res);
              //侧边栏进入判断入口
              try {
                this.$wx.invoke('getContext', {}, (res) => {
                  if (res.err_msg == 'getContext:ok') {
                    localStorage.setItem('platform_sidebar', res.entry);
                    cb();
                  } else {
                    //错误处理
                    alert(res.err_msg);
                  }
                });
              } catch (e) {
                console.log(e, '兼容pc无invoke方法');
                localStorage.setItem('platform_sidebar', 'normal'); //PC端默认设置为normal
                cb();
              }
              // 回调
            },
            fail: (res) => {
              if (res.errMsg === 'agentConfig:fail') {
                console.log(res, '兼容pc无agentConfig方法');
                localStorage.setItem('platform_sidebar', 'normal'); //PC端默认设置为normal
                cb();
              }
              if (res.errMsg.indexOf('function not exist') > -1) {
                alert('版本过低请升级');
              }
              alert(`agentConfig:${res.errMsg}`);
              console.log(`agentConfig 注册失败===>>>${res.errMsg}`);
            },
          });
        });
      }
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem('platform_user')) || '';
    this.changeBind = this.$route.query.changeBind;
  },
  beforeRouteLeave(to, from, next) {
    if (to.path == '/') {
      //必要的
      this.$wx.closeWindow();
    } else {
      next();
    }
  },
};
</script>

<style lang="less" scoped>
.user-login {
  position: relative;

  .user-bg {
    width: 100%;
    height: 88px;
  }

  .concat-account {
    background-color: @gary-white;
    top: 24px;
    left: 16px;
    position: absolute;
    padding: 24px 12px;
    width: 319px;
    border-radius: 4px;

    .weixin-log {
      position: absolute;
      top: 0px;
      right: 12px;
      width: 79px;
    }

    .top-title {
      font-size: 12px;
      font-weight: 400;
      color: @gary-wxb-gray-3;
      line-height: 17px;
    }

    .concat-account-content {
      margin-top: 16px;

      .person-card {
        display: flex;
        align-items: center;

        img {
          width: 48px;
          height: 48px;
          border-radius: 48px;
          margin-right: 13px;
        }

        .person-detail {
          .name {
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            line-height: 20px;
          }

          .position {
            font-size: 12px;
            font-weight: 400;
            color: @gary-wxb-gray-3;
            line-height: 17px;
          }
        }
      }

      .divider {
        margin-top: 24px;
        height: 0.5px;
        background: #f6f6f6;
      }

      .account {
        width: 100%;
        margin-top: 24px;

        .person-login {
          padding: 15px 12px 14px 9px;
          background-color: #f5f6f7;
          border-radius: 4px;
          margin-top: 12px;
        }

        .person-password {
          margin-top: 16px;
        }

        .title {
          font-size: 12px;
          font-weight: 400;
          color: @gary-wxb-gray-3;
          line-height: 17px;
        }

        .btn {
          width: 319px;
          margin-top: 32px;
          border-radius: 6px;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
